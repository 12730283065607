<template>
  <div id="onboarding">
    <transition name="fade" mode="out-in">
      <form key="page1" v-if="show == 'zip'" @submit.prevent="submitZip">
        <h1 class="main-heading is-size-1-touch">BCubed Made Easy</h1>
        <p class="main-text">
          Comply with the regulations that affect you and your business.
        </p>
        <p class="main-subtext">Enter zipcode to get started!</p>
        <b-field
          :type="validation.hasError('zipcode') ? 'is-danger' : ''"
          :message="
            validation.hasError('zipcode')
              ? validation.firstError('zipcode')
              : ''
          "
        >
          <float-label :class="{ error: validation.hasError('zipcode') }">
            <input class="input" v-focus v-model="zipcode" placeholder="Enter Zipcode" />
          </float-label>
        </b-field>
        <div class="control action">
          <button type="submit" class="button is-primary is-rounded">Check Zipcode</button>
        </div>
        <router-link
          class="login-link"
          :to="userLoggedOut ? '/login' : '/dashboard'"
        >Been here before? Click here to access your account.</router-link>
        <b-modal :active.sync="showRegulationModal">
          <div class="box">
            <h4 class="title is-size-3 is-spaced">
              Dental Effluent Guidelines: 40 CFR part 441.50 (b)
            </h4>
            <div class="content">
              <h6>Sections 441.50 (b)</h6>
              <p>Dental Dischargers or an agent or representative of the dental discharger
                must maintain and make available for inspection in either physical or electronic
                form, for a minimum of three years:
              </p>
              <ol>
                <li>
                  Documentation of the date, person(s) conducting the inspection, and results of
                  each inspection of the amalgam separator(s) or equivalent device(s), and a summary
                  of follow-up actions, if needed.
                </li>
                <li>
                  Documentation of amalgam retaining container or equivalent container replacement
                  (including the date, as applicable).
                </li>
                <li>
                  Documentation of all dates that collected dental amalgam is picked up or shipped
                  for proper disposal in accordance with 40 CFR 261.5(g)(3), and the name of the
                  permitted or licensed treatment, storage or disposal facility receiving the
                  amalgam retaining containers.
                </li>
                <li>
                  Documentation of any repair or replacement of an amalgam separator or equivalent
                  device, including the date, person(s) making the repair or replacement, and a
                  description of the repair or replacement (including make and model).
                </li>
                <li>
                  Dischargers or an agent or representative of the dental discharger must maintain
                  and make available for inspection in either physical or electronic form the
                  manufacturers operating manual for the current device.
                </li>
              </ol>
            </div>
            <div class="control action">
              <button
                type="button"
                class="button is-primary is-rounded"
                @click="showRegulationModal = false"
              >Ok, got it!</button>
            </div>
          </div>
        </b-modal>
      </form>
      <div key="page2" v-else-if="show == 'access_code'">
        <ul class="steps is-medium is-centered has-content-centered">
          <li class="steps-segment">
            <a class="has-text-dark">
              <span class="steps-marker is-primary">
                <b-icon icon="map-marker" />
              </span>
              <div class="steps-content">
                <p class="heading">Zipcode</p>
              </div>
            </a>
          </li>
          <li class="steps-segment is-active has-gaps">
            <a class="has-text-dark">
              <span class="steps-marker is-primary">
                <b-icon icon="book-open" />
              </span>
              <div class="steps-content">
                <p class="heading">Rewards Program</p>
              </div>
            </a>
          </li>
          <li class="steps-segment has-gaps">
            <span class="steps-marker is-primary is-hollow">
              <b-icon icon="account" />
            </span>
            <div class="steps-content">
              <p class="heading">User Information</p>
            </div>
          </li>
          <li class="steps-segment">
            <span class="steps-marker is-primary is-hollow">
              <b-icon icon="check" />
            </span>
            <div class="steps-content">
              <p class="heading">Complete</p>
            </div>
          </li>
        </ul>
        <div class="response mb-2">
          <h2 class="title is-spaced">Access Code</h2>
          <h3 class="subtitle">
            BCubed is only accessible by invitation. Please enter the your access code below.
          </h3>
          <form @submit.prevent="submitAccessCode">
            <b-field
              :type="validation.hasError('access_code') ? 'is-danger' : ''"
              :message="validation.hasError('access_code') ? validation.firstError('access_code') : ''"
            >
              <b-input
                class="access-code-input"
                v-model="accessCode"
                placeholder="AB-CDE-FGH"
              />
            </b-field>
            <div class="control action">
              <button type="submit" class="button is-primary is-rounded">Next</button>
            </div>
          </form>
        </div>
      </div>
      <div key="page3" v-else-if="show == 'program'">
        <ul class="steps is-medium is-centered has-content-centered">
          <li class="steps-segment">
            <a class="has-text-dark">
              <span class="steps-marker is-primary">
                <b-icon icon="map-marker" />
              </span>
              <div class="steps-content">
                <p class="heading">Zipcode</p>
              </div>
            </a>
          </li>
          <li class="steps-segment is-active has-gaps">
            <a class="has-text-dark">
              <span class="steps-marker is-primary">
                <b-icon icon="book-open" />
              </span>
              <div class="steps-content">
                <p class="heading">Rewards Program</p>
              </div>
            </a>
          </li>
          <li class="steps-segment has-gaps">
            <span class="steps-marker is-primary is-hollow">
              <b-icon icon="account" />
            </span>
            <div class="steps-content">
              <p class="heading">User Information</p>
            </div>
          </li>
          <li class="steps-segment">
            <span class="steps-marker is-primary is-hollow">
              <b-icon icon="check" />
            </span>
            <div class="steps-content">
              <p class="heading">Complete</p>
            </div>
          </li>
        </ul>
        <div class="response mb-2">
          <h2 class="title is-spaced">BCubed Rewards Available</h2>
          <h3 class="subtitle">
            Select one of the programs that affects you.<br>
            Learn about your compliance.
          </h3>
        </div>
        <form @submit.prevent="collectEmail">
          <b-field
            :type="validation.hasError('program') ? 'is-danger' : ''"
            :message="
              validation.hasError('program')
                ? validation.firstError('program')
                : ''
            "
          >
            <b-select
              class="program-select"
              v-model="program"
              placeholder="Select Rewards Program"
            >
              <option v-for="program in programs" :key="program.id" :value="program.id">
                {{ program.name }}
              </option>
            </b-select>
          </b-field>

          <div class="control action">
            <button type="submit" class="button is-primary is-rounded">Next</button>
          </div>
        </form>
      </div>
      <div key="page4" v-else-if="show == 'nameEmail'">
        <ul class="steps is-medium is-centered has-content-centered">
          <li class="steps-segment">
            <a class="has-text-dark">
              <span class="steps-marker is-primary">
                <b-icon icon="map-marker" />
              </span>
              <div class="steps-content">
                <p class="heading">Zipcode</p>
              </div>
            </a>
          </li>
          <li class="steps-segment">
            <a class="has-text-dark">
              <span class="steps-marker is-primary">
                <b-icon icon="book-open" />
              </span>
              <div class="steps-content">
                <p class="heading">Reward Program</p>
              </div>
            </a>
          </li>
          <li class="steps-segment is-active has-gaps">
            <span class="steps-marker is-primary">
              <b-icon icon="account" />
            </span>
            <div class="steps-content">
              <p class="heading">User Information</p>
            </div>
          </li>
          <li class="steps-segment">
            <span class="steps-marker is-primary is-hollow">
              <b-icon icon="check" />
            </span>
            <div class="steps-content">
              <p class="heading">Complete</p>
            </div>
          </li>
        </ul>
        <div class="response">
          <h2 class="title is-spaced">Register for a Free Account</h2>
          <h3 class="subtitle">
            This FREE account keeps a record of all your BCubed Rewards, including the information you have read,
            proven that you have learnt, and taken action on.
          </h3>
        </div>
        <form @submit.prevent="validateEmail" class="email-form">
          <b-field
            v-if="programIsOrganizational"
            :type="validation.hasError('organizationName') ? 'is-danger' : ''"
            :message="
              validation.hasError('organizationName') ? validation.firstError('organizationName') : ''
            "
          >
            <float-label :class="{ error: validation.hasError('organizationName') }">
              <input class="input" v-model.lazy="organizationName" placeholder="Office Name" />
            </float-label>
          </b-field>
          <b-field
            :type="validation.hasError('email') ? 'is-danger' : ''"
            :message="
              validation.hasError('email') ? validation.firstError('email') : ''
            "
          >
            <float-label :class="{ error: validation.hasError('email') }">
              <input class="input" v-model.lazy="email" placeholder="Your Email Address" />
            </float-label>
          </b-field>
<!--          <b-field-->
<!--            :type="validation.hasError('accessCode') ? 'is-danger' : ''"-->
<!--            :message="-->
<!--              validation.hasError('accessCode')-->
<!--                ? validation.firstError('accessCode')-->
<!--                : ''-->
<!--            "-->
<!--            :addons="false"-->
<!--          >-->
<!--            <float-label :class="{ error: validation.hasError('accessCode') }">-->
<!--              <input-->
<!--                class="input"-->
<!--                v-model.lazy="accessCode"-->
<!--                placeholder="Access Code / License Key"-->
<!--                v-mask="accessCodeMask"-->
<!--              />-->
<!--            </float-label>-->
<!--            <button-->
<!--              type="button"-->
<!--              @click="showAccessCodeInfo = true;"-->
<!--              class="button is-light is-small is-rounded info-modal"-->
<!--            >-->
<!--              What's this?-->
<!--            </button>-->
<!--          </b-field>-->
          <div class="control action">
            <button type="submit" class="button is-primary is-rounded">{{ buttonLabel }}</button>
          </div>
        </form>
      </div>
      <div
        class="validation"
        :class="{ 'dialog-open': showEmailOptionsDialog }"
        key="confirmation"
        v-else-if="show == 'validationcode'"
      >
        <ul v-if="mode == 'standalone'" class="steps is-medium is-centered has-content-centered">
          <li class="steps-segment">
            <a class="has-text-dark">
              <span class="steps-marker is-primary">
                <b-icon icon="map-marker" />
              </span>
              <div class="steps-content">
                <p class="heading">Zipcode</p>
              </div>
            </a>
          </li>
          <li class="steps-segment">
            <a class="has-text-dark">
              <span class="steps-marker is-primary">
                <b-icon icon="book-open" />
              </span>
              <div class="steps-content">
                <p class="heading">Reward Program</p>
              </div>
            </a>
          </li>
          <li class="steps-segment is-active has-gaps">
            <span class="steps-marker is-primary">
              <b-icon icon="account" />
            </span>
            <div class="steps-content">
              <p class="heading">User Information</p>
            </div>
          </li>
          <li class="steps-segment">
            <span class="steps-marker is-primary is-hollow">
              <b-icon icon="check" />
            </span>
            <div class="steps-content">
              <p class="heading">Complete</p>
            </div>
          </li>
        </ul>
        <h3 v-if="mode != 'password_recovery'" class="subtitle">
          For your privacy and security, we require all accounts to have a
          validated email address. Don't worry, you will only have to do this
          once.
        </h3>
<!--        eslint-disable-next-line-->
        <label class="label is-medium">Enter Validation Code</label>
        <validation-code-input v-model="validationCode" />
        <p>
          A validation code has been sent to
          <strong>{{ email }}</strong>.
<!--          eslint-disable-next-line-->
          <a @click="openEmailOptions();">Didn't receive email?</a>
        </p>
        <b-modal :active.sync="showEmailOptionsDialog">
          <div class="box">
            <form @submit.prevent="sendValidationCode">
              <h4 class="title is-size-4">Resend Validation Code</h4>
              <b-field
                :type="validation.hasError('email') ? 'is-danger' : ''"
                :message="
                  validation.hasError('email')
                    ? validation.firstError('email')
                    : ''
                "
              >
                <float-label :class="{ error: validation.hasError('email') }">
                  <input
                    class="input"
                    v-focus
                    :disabled="emailIsImmutable"
                    v-model.lazy="email"
                    placeholder="Email Address"
                  />
                </float-label>
              </b-field>
              <div class="control action">
                <button type="submit" class="button is-primary is-rounded">
                  Send Validation Code
                </button>
              </div>
            </form>
          </div>
        </b-modal>
      </div>
      <form @submit.prevent="savePassword" key="password" v-else-if="show == 'password'">
        <template v-if="mode == 'standalone'">
          <h3 class="is-size-3">Enter your name:</h3>
          <b-field
            :type="validation.hasError('firstName') ? 'is-danger' : ''"
            :message="
              validation.hasError('firstName')
                ? validation.firstError('firstName')
                : ''
            "
          >
            <float-label>
              <input class="input" v-focus v-model="firstName" placeholder="First" />
            </float-label>
          </b-field>
          <b-field
            :type="validation.hasError('lastName') ? 'is-danger' : ''"
            :message="
              validation.hasError('lastName')
                ? validation.firstError('lastName')
                : ''
            "
          >
            <float-label>
              <input class="input" v-model="lastName" placeholder="Last" />
            </float-label>
          </b-field>
        </template>
        <h3 class="is-size-3">Choose a password:</h3>
        <p>Must be at least 8 characters long.</p>
        <b-field
          :type="validation.hasError('password') ? 'is-danger' : ''"
          :message="
            validation.hasError('password')
              ? validation.firstError('password')
              : ''
          "
        >
          <float-label>
<!--            eslint-disable-next-line-->
            <input
              v-if="mode == 'standalone'"
              class="input"
              type="password"
              v-model="password"
              placeholder="Password"
            />
<!--            eslint-disable-next-line-->
            <input
              v-else
              v-focus
              class="input"
              type="password"
              v-model="password"
              placeholder="Password"
            />
          </float-label>
        </b-field>
        <b-field
          :type="validation.hasError('passConfirm') ? 'is-danger' : ''"
          :message="
            validation.hasError('passConfirm')
              ? validation.firstError('passConfirm')
              : ''
          "
        >
          <float-label>
<!--            eslint-disable-next-line-->
            <input class="input" type="password" v-model="passConfirm" placeholder="Confirm" />
          </float-label>
        </b-field>
        <div class="control action">
          <button type="submit" class="button is-primary is-rounded">Save Password</button>
        </div>
      </form>
      <div v-else-if="show == 'welcome'">
        <h2>Congratulations!</h2>
        <h3>Your registration is now complete. We will get in touch with you as soon as your rewards are ready.</h3>
      </div>
      <div key="spinner" v-else-if="show == 'spinner'">
        <Spinner v-bind:label="statusMessage" />
      </div>
    </transition>
    <b-modal  :active.sync="showAccessCodeInfo">
      <div class="box">
        <h4 class="title is-size-3 is-spaced">Access Code Information</h4>
        <h6 class="subtitle is-size-5">
          MMC Health is provided free of charge, thanks to our industry
          partners.
        </h6>
        <ul>
          <li class="spaced-list-item">
            <strong>My Dealer Gave me an Access Code</strong>
            <br />Great!
            Use the access code to signup for your Free Account.
          </li>
          <li>
            <strong>I don't have an Access Code - how do I get one?</strong>
            <br />If you do not have an access code, please contact Sable
            Industries at 1-800-368-8106 - they will be happy to provide you
            with a free code sponsored by Stephanie Smiles.
          </li>
        </ul>
        <div class="control action">
          <button
            type="button"
            class="button is-primary is-rounded"
            @click="showAccessCodeInfo = false;"
          >Ok, got it!</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import FloatLabel from 'vue-float-label/components/FloatLabel.vue';
import SimpleVueValidator from 'simple-vue-validator';
import { mask } from 'vue-the-mask';
import Spinner from './utility/Spinner.vue';
import ValidationCodeInput from './utility/ValidationCodeInput.vue';

SimpleVueValidator.setMode('conservative');

const { Validator } = SimpleVueValidator;

export default {
  name: 'onboarding-component',
  mixins: [SimpleVueValidator.mixin],
  components: {
    Spinner,
    ValidationCodeInput,
    FloatLabel,
  },
  directives: {
    mask,
    focus: {
      inserted: function inserted(el) {
        if (el.tagName === 'INPUT') {
          el.focus();
        } else {
          el.querySelector('input').focus();
        }
      },
    },
  },
  props: {
    mail: String,
    mode: {
      type: String,
      default: 'standalone',
    },
  },
  data() {
    return {
      userLoggedOut: window.me === undefined || window.me?.uid === 0,
      zipcode: '',
      firstName: '',
      lastName: '',
      email: this.mail ? this.mail : '',
      organizationName: this.organizationName ? this.organizationName : '',
      accountEmail: this.mail ? this.mail : '',
      emailIsTaken: false,
      emailStatus: this.mail ? '' : 'sent',
      emailIsImmutable: this.mail !== undefined,
      category: '',
      statusMessage: 'Loading',
      password: '',
      passConfirm: '',
      show: '',
      responseType: '',
      showEmailOptionsDialog: false,
      showAccessCodeInfo: false,
      accessCode: '',
      accessCodeInvalid: false,
      accessCodeExpired: false,
      validationCode: ['', '', '', '', '', ''],
      programs: {},
      manufacturers: [],
      program: null,
      submitted: false,
      showRegulationModal: false,
      appState: 'initial',
      userId: null,
      organizationId: null,
      programIsOrganizational: false,
      accessCodeMask: {
        mask: 'AA-ZZZ-ZZZ',
        tokens: {
          Z: { pattern: /[0-9a-zA-Z]/, transform: (v) => v.toLocaleUpperCase() },
          A: { pattern: /[a-zA-Z]/, transform: (v) => v.toLocaleUpperCase() },
        },
      },
    };
  },
  mounted() {
    if (this.mode === 'standalone') {
      this.show = 'zip';
    }
    if (
      this.mode === 'password_recovery'
      || this.mode === 'account_validation'
    ) {
      this.appState = 'resending_validation_code';
    }
    this.$emit('mount');
  },
  computed: {
    validationCodeString: function validationCodeString() {
      return this.validationCode.join('').length === 6
        ? `${this.validationCode[0]}${this.validationCode[1]}${
          this.validationCode[2]
        }-${this.validationCode[3]}${this.validationCode[4]}${
          this.validationCode[5]
        }`
        : '';
    },
    buttonLabel: function buttonLabel() {
      return 'Create Account';
    },
    manufacturerSuggestions: function manufacturerSuggestions() {
      return this.manufacturers.filter((key) => (
        key
          .toString()
          .toLowerCase()
          .indexOf(this.separatorMake.toLowerCase()) >= 0
      ));
    },
  },
  watch: {
    validationCodeString: function validationCodeString(newCode) {
      if (newCode.length) {
        this.appState = 'checking_validation_code';
      }
    },
    appState: function appState(newAppState) {
      switch (newAppState) {
        case 'initial':
          this.show = 'zip';
          break;
        case 'checking_zipcode':
          this.show = 'spinner';
          this.statusMessage = 'Looking up regulations for your area';
          this.$get(
            `/bcubed/zip/${this.zipcode}`,
            3000,
          )
            .then((response) => {
              this.state = response['data']['state'];
              this.appState = 'access_code';
            })
            .catch(() => {
              this.$validate('zipcode');
              this.appState = 'initial';
            });
          break;
        case 'access_code':
          this.show = 'access_code';
          break;
        case 'program':
          this.show = 'program';
          break;
        case 'collect_account_info':
          this.show = 'nameEmail';
          break;
        case 'registering':
          this.accountEmail = this.email;
          this.statusMessage = '';
          this.show = 'spinner';

          this.$post('/bcubed/register/', {
            email: this.email,
            organization: this.programIsOrganizational ? this.organizationName : '',
            program: this.program,
            access_code: this.accessCode,
            zipcode: this.zipcode,
          })
            .then((response) => {
              console.log(response);
              this.userId = response.data.user_id;
              this.organizationId = (this.programIsOrganizational) ? response.data.organization_id : 0;
              this.appState = 'collect_validation_code';
            })
            .catch((error) => {
              console.log(error)
              if (error.response) {
                if (error.response.data.email_taken) {
                  this.emailIsTaken = true;
                  this.$validate('email');
                }
              }
              this.appState = 'collect_account_info';
            });
          break;
        case 're_registering':
          this.accountEmail = this.email;
          this.statusMessage = '';
          this.show = 'spinner';
          this.$post('/registerapi/register/dental_user', {
            email: this.email,
            separator: {
              id: this.separator,
              model: this.separatorModel,
              make: this.separatorMake,
            },
            zipcode: this.zipcode,
            accessCode: this.accessCode,
          })
            .then((response) => {
              this.closeEmailOptions();
              this.emailStatus = 'sent';
              this.appState = 'collect_validation_code';
              window.drupalSettings.nodejs.authToken = response.data.session.token;
              if (window.Drupal.Nodejs.socket !== false) {
                window.Drupal.Nodejs.socket.close();
                window.Drupal.Nodejs.connect();
                window.Drupal.Nodejs.socket.on('message', this.asyncMessage);
              }
            })
            .catch((error) => {
              if (error.response && error.response.data.email_taken) {
                this.emailIsTaken = true;
                this.$validate('email');
              }
              this.appState = 'collect_validation_code';
            });
          break;
        case 'collect_validation_code':
          this.show = 'validationcode';
          this.clearValidationCode();
          break;
        case 'checking_validation_code':
          this.show = 'spinner';
          this.$post('/register/validate', {
            code: this.validationCodeString,
            id: this.userId
          })
            .then((response) => {
              console.log(response, 'validation');
              switch (response.data.status) {
                case 'success':
                  this.$emit('validated');
                  this.appState = 'collect_password';
                  break;
                case 'invalid':
                  this.emailStatus = 'invalid';
                  this.appState = 'collect_validation_code';
                  break;
                case 'expired':
                  this.emailStatus = 'expired';
                  this.appState = 'collect_validation_code';
                  this.openEmailOptions();
                  break;
                default:
                  break;
              }
            })
            .catch(() => {
              this.show = 'validationcode';
            });
          break;
        case 'resending_validation_code':
          this.statusMessage = '';
          this.show = 'spinner';
          this.$put('/register/validate', {
            id: this.userId,
          })
            .then(() => {
              this.closeEmailOptions();
              this.emailStatus = 'sent';
              this.appState = 'collect_validation_code';
            })
            .catch(() => {
              this.appState = 'collect_validation_code';
            });
          break;
        case 'collect_password':
          this.show = 'password';
          break;
        case 'register_message':
          this.show = 'register_message';
          break;
        case 'saving_password':
          this.statusMessage = 'Saving Password';
          this.show = 'spinner';
          this.$post('/register/save', {
            id: this.userId,
            password: this.password,
            first_name: this.firstName,
            last_name: this.lastName,
          })
            .then((response) => {
              if (response.data.status === 'success') {
                localStorage.setItem('X-CSRF-Token', response.data.data.csrf)
                this.$setCSRFToken(response.data.data.csrf)
                this.$setAPIKey(response.data.data.api_key)
                window.me = {}
                window.me.uuid = response.data.data.uuid;
                window.me.api_key = response.data.data.api_key;
                window.me.first_name = response.data.data.details.first_name;
                window.me.last_name = response.data.data.details.last_name;
                window.me.email = response.data.data.details.email;
                window.me.organization_id = response.data.data.details.organization_id;
                localStorage.setItem("me", JSON.stringify(window.me));
                this.$router.push('dashboard');
                this.appState = 'welcome';
              }
            }).catch((error) => {
              // handle error
              console.log(error);
              this.appState = 'collect_password';
            });
          break;
        case 'welcome':
          this.show = 'welcome';
          break;
        default:
          break;
      }
    },
  },
  validators: {
    zipcode: function zipcode(value) {
      return Validator.value(value)
        .required()
        .digit('Invalid zipcode.')
        .length(5);
    },
    email: function email(value) {
      return Validator.value(value)
        .required()
        .email()
        .custom(() => {
          if (this.emailIsTaken) {
            this.emailIsTaken = false;
            return 'An account has already been created with this email address.';
          }
          return undefined;
        });
    },
    organizationName: function organizationName(value) {
      return Validator.value(value).required()
    },
    accessCode: function accessCode(value) {
      return Validator.value(value)
        .required()
        .custom(() => {
          if (this.accessCodeInvalid) {
            this.accessCodeInvalid = false;
            return 'Invalid access code. Click on "What\'s this?" for help.';
          }
          if (this.accessCodeExpired) {
            this.accessCodeExpired = false;
            return "The access code you've entered has expired. Click on \"What's this?\" for help obtaining a new one.";
          }
          return undefined;
        });
    },
    firstName: function firstName(value) {
      return Validator.value(value).required();
    },
    lastName: function lastName(value) {
      return Validator.value(value).required();
    },
    password: function password(value) {
      return Validator.value(value)
        .required()
        .minLength(8, 'Password must be at least 8 characters long.');
    },
    'passConfirm, password': function passConfirmValid(passConfirm, password) {
      if (this.submitted || this.validation.isTouched('passConfirm')) {
        return Validator.value(passConfirm)
          .required()
          .match(password);
      }
      return undefined;
    },
    program: function program(value) {
      return Validator.value(value).required();
    },
  },
  methods: {
    submitAccessCode() {
      this.$validate(['accessCode']).then((success) => {
        if (success) {
          this.$post('/bcubed/access_code', {
            'access_code': this.accessCode,
            'state': this.state
          }).then((response) => {
            this.programIsOrganizational = response.data.is_organizational;
            this.program = response.data.program;
            this.appState = 'collect_account_info';
          }).catch((err) => {
            console.log(err);
          })
        }
      });
    },
    clearValidationCode: function clearValidationCode() {
      this.validationCode = ['', '', '', '', '', ''];
    },
    submitZip() {
      this.$emit('onboarding-started');
      this.$validate(['zipcode']).then((success) => {
        if (success) {
          this.appState = 'checking_zipcode';
        }
      });
    },
    validateEmail() {
      if (this.programIsOrganizational) {
        this.$validate(['email', 'organizationName']).then((success) => {
          if (success) {
            this.appState = 'registering';
          }
        });
      }
      else {
        this.$validate(['email']).then((success) => {
          if (success) {
            this.appState = 'registering';
          }
        });
      }
    },
    collectEmail() {
      this.submitted = true;
      this.$validate(['program']).then(
        (success) => {
          if (success) {
            this.submitted = false;
            this.appState = 'collect_account_info';
          }
        },
      );
    },
    savePassword() {
      this.submitted = true;
      if (this.mode === 'standalone') {
        this.$validate([
          'firstName',
          'lastName',
          'password',
          'passConfirm',
        ]).then((success) => {
          if (success) {
            this.submitted = false;
            this.appState = 'saving_password';
          }
        });
      } else {
        this.$validate(['password', 'passConfirm']).then((success) => {
          if (success) {
            this.submitted = false;
            this.appState = 'saving_password';
          }
        });
      }
    },
    openEmailOptions() {
      this.showEmailOptionsDialog = true;
    },
    closeEmailOptions() {
      this.showEmailOptionsDialog = false;
    },
    sendValidationCode() {
      this.$validate(['email']).then((success) => {
        if (success) {
          if (this.email === this.accountEmail) {
            this.appState = 'resending_validation_code';
          } else {
            this.appState = 're_registering';
          }
        }
      });
    },
    asyncMessage(message) {
      // handle push messages from server
      if (message.data.type === 'statusMessage') {
        this.statusMessage = message.data.statusMessage;
      }
      if (message.data.type === 'email_status') {
        if (message.data.status === 'delivered') {
          this.emailStatus = 'delivered';
        } else if (
          message.data.status === 'bounce'
          || message.data.status === 'dropped'
        ) {
          this.emailStatus = 'failed';
          this.openEmailOptions();
        }
      }
    },
  },
};
</script>

<style>

.vfl-label {
  text-align: left;
}

.vfl-has-label {
  margin-top: 20px;
}

.vfl-has-label.error input {
  border-bottom: 2px solid #ff8888;
}

.email-delivery-notification i.mdi.mdi-24px::before {
  font-size: 28px;
}
</style>

<style scoped>
.response .title {
  font-size: 1.9rem;
}
.main-heading {
  font-family: serif;
  font-weight: bold;
  font-size: 4rem;
  line-height: 4.2rem;
}

.main-text {
  margin-top: 30px;
  font-size: 1.5em;
}

.main-subtext {
  margin-top: 0.5em;
  font-size: 1.5em;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.whoosh-enter-active {
  transition: all 0.3s ease;
}
.whoosh-leave-active {
  transition: all 0.2s ease;
}
.whoosh-enter {
  opacity: 0;
  transform: translateY(30px);
}

.whoosh-leave-to {
  opacity: 0;
  transform: translate(300px);
}

div.officeinfo {
  text-align: left;
  border-bottom: 1px solid #ddd;
  margin: 10px;
}

div.officeinfo > a {
  display: block;
  float: right;
  margin-top: -50px;
}

.material-icons {
  vertical-align: middle;
}

.material-icons.error {
  width: 24px;
  color: #ff8888;
}

.material-icons.success {
  width: 24px;
  color: #88ff8d;
}

.validation {
  transition: color 0.2s ease;
}

.validation.dialog-open {
  color: #ddd;
}

.validation.dialog-open a {
  color: #acb9be;
}

.email-delivery-notification {
  color: #000;
  padding-top: 30px;
  font-size: 1.5rem;
}
.email-options-dialog {
  position: relative;
  color: #000;
  border: 3px solid #f3f3f3;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.52);
}

a.close-link {
  color: #636363;
  position: absolute;
  top: 5px;
  right: 5px;
}
a.close-link:hover {
  cursor: pointer;
}
a {
  color: #1487ba;
}
a:hover {
  text-decoration: underline;
  cursor: pointer;
}

.spinner {
  margin: 0;
  width: 60px;
  text-align: center;
  display: inline-block;
}

.spinner > div {
  width: 12px;
  height: 12px;
  background-color: #333;

  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.separator-select {
  display: inline-block;
}

.control.action,
button.info-modal {
  margin-top: 20px;
}

.login-link {
  display: inline-block;
  margin-top: 20px;
}

.spaced-list-item:not(:last-child) {
  margin-bottom: 15px;
}
.email-form .field div.vfl-has-label {
  width: 300px;
}
</style>
