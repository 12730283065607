<template>
  <div id="main-page">
    <nav class="navbar has-shadow is-spaced">
      <div class="container">
        <div class="navbar-brand">
          <a class="navbar-item is-size-3" to="/">
            <h1 class="">BCubed</h1>
            <!--            <img src="assets/logo.svg" alt="BCubed logo" />-->
          </a>
          <div
            class="navbar-burger burger"
            @click="mobileMenuActive = !mobileMenuActive;"
            :class="{ 'is-active': mobileMenuActive }"
            @keydown="mobileMenuActive = !mobileMenuActive;"
          >
            <span></span> <span></span> <span></span>
          </div>
        </div>
        <div class="navbar-menu" :class="{ 'is-active': mobileMenuActive }">
          <div class="navbar-end">
            <router-link to="/pricing" class="navbar-item">Pricing</router-link>
            <router-link to="/support" class="navbar-item">Support</router-link>
            <template v-if="userLoggedOut">
              <div class="navbar-item">
                <router-link
                  :disabled="$route.name == 'login'"
                  class="button is-primary is-outlined"
                  to="login">
                  Login
                </router-link>
              </div>
            </template>
            <div v-else class="navbar-item">
              <router-link class="button is-primary is-outlined" to="/dashboard">
                Dashboard
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="main-wrapper">
      <div id="page-top">
        <div class="hero is-small">
          <div class="hero-body">
            <div class="container">
              <div class="columns is-vcentered reverse">
                <div class="column loadable-container">
                  <div class="transition-wrapper">
                    <transition :name="transitionName" mode="out-in">
                      <router-view @onboarding-started="collapsed = true;" />
                    </transition>
                  </div>
                </div>
                <div class="column">
                  <figure class="image is-square">
                    <img alt="office" src="/assets/dental-office.svg" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="extra-content loadable-container" :class="{ collapsed }">
          <!--          <div class="section"><countdown /></div>-->
          <section class="hero is-light">
            <div class="hero-body">
              <div class="container has-text-centered">
                <h1 class="title is-spaced">You're In Good Company</h1>
                <h2 class="subtitle">
                  Hundreds of dentists are already using BCubed for compliance.
                </h2>
                <a href="#" class="button is-outlined is-rounded is-primary">
                  Discover Why
                </a>
              </div>
            </div>
          </section>
          <section class="hero">
            <div class="hero-body">
              <div class="container">
                <div class="level">
                  <div class="level-left">
                    <div class="level-item">
                      <div>
                        <h1 class="title is-spaced">Try it for free</h1>
                        <h2 class="subtitle is-4">
                          Already using BCubed?
                          <router-link :to="userLoggedOut ? '/login' : '/dashboard'">
                            Sign in
                          </router-link>
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div class="level-right">
                    <router-link
                      to="/"
                      class="button is-large is-primary is-rounded"
                      @click.native="scrollTop">
                      Get Started
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
    <div class="footer-top">
      <div class="container">
        <div class="level">
          <div class="level-left">
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">Provided By</p>
                <a target="_blank" href="https://adtumbler.org"
                   class="is-flex is-align-items-center"
                   style="height:70px;"
                >
                  AdTumbler, Inc.
                </a>
              </div>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Sponsored By</p>
              <a target="_blank" href="https://sableindustriesinc.com">
                <img
                  src="/assets/sablelogo.png"
                  alt="Sable Industries"
                />
              </a>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">Sponsored By</p>
                <a target="_blank" href="https://biopureproducts.com">
                  <img
                    src="/assets/biopurelogo.png"
                    alt="Bio-Pure Products"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="footer">
      <div class="container">
        <div class="level">
          <div class="level-left">
            <router-link to="/contact" class="level-item">
              Contact Us
            </router-link>
            <a class="level-item" to="/terms"> Terms &amp; Conditions </a>
            <a class="level-item" to="/privacy"> Privacy Policy </a>
            <a class="level-item" to="/returns"> Returns </a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
// import Login from '@/components/utility/Login'

export default {
  name: 'home-page',
  // components: {
  //   Login
  // },
  data() {
    return {
      userLoggedOut: !window.me?.uuid,
      mobileMenuActive: false,
      transitionName: '',
      collapsed: false,
    };
  },
  watch: {
    $route(to, from) {
      if (to.name === 'login' && from.name === 'onboarding') {
        this.transitionName = 'slide-left';
      } else {
        this.transitionName = 'slide-right';
      }
    },
  },
};
</script>

<style scoped>
#main-page {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.main-wrapper {
  flex: 1;
}

.footer {
  padding: 1.5rem;
  background-color: #f0f0f0;
}

.footer-top {
  padding: 3rem 1.5rem;
  border-top: 1px solid #e0e0e0;
  background-color: #f8f8f8;
  background-image: linear-gradient(#f8f8f8, #fff);
}
</style>
