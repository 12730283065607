<template>
  <div id="login">
    <transition name="fade" mode="out-in">
      <form v-if="show == 'email'" key="email" @submit.prevent="submitEmail">
        <h1 class="title is-1 is-spaced">{{ title }}</h1>
        <h3 class="subtitle is-3">{{ subtitle }}</h3>
        <b-field
          :type="validation.hasError('email') ? 'is-danger' : ''"
          :message="
            validation.hasError('email') ? validation.firstError('email') : ''
          "
        >
          <float-label :class="{ error: validation.hasError('email') }">
            <b-input
              v-focus
              name="email"
              v-model="email"
              placeholder="Enter email address"
            />
          </float-label>
        </b-field>
        <div class="control action">
          <button type="submit" class="button is-primary is-rounded">
            Next
          </button>
        </div>
        <router-link class="start-link" to="/">
          <b-icon icon="arrow-left" />
          <span>Just kidding, I'm new here!</span>
        </router-link>
      </form>
      <div key="spinner" v-else-if="show == 'spinner'">
        <Spinner v-bind:label="statusMessage" />
      </div>
      <form
        key="password"
        @submit.prevent="submitPassword"
        v-else-if="show == 'password'"
      >
        <h1 class="title is-1 is-spaced">{{ title }}</h1>
        <h3 class="subtitle is-3">{{ subtitle }}</h3>
        <input class="hidden-input" name="username" v-model="email" />
        <b-field
          :type="validation.hasError('password') ? 'is-danger' : ''"
          :message="
            validation.hasError('password')
              ? validation.firstError('password')
              : ''
          "
        >
          <float-label :class="{ error: validation.hasError('password') }">
            <b-input
              v-focus
              name="password"
              type="password"
              v-model="password"
              placeholder="Password"
            />
          </float-label>
        </b-field>
        <div class="control action">
          <button type="submit" class="button is-primary is-rounded mr-1">
            Login
          </button>
          <button
            type="button"
            class="button is-light is-rounded"
            @click="enterPasswordRecovery"
          >
            I Forgot My Password
          </button>
        </div>
      </form>
      <div key="fail" v-else-if="show == 'login_fail'">
        <h2>Login Unsuccessful</h2>
        <p>{{ failureMessage }}</p>
      </div>
<!--      <onboarding-->
<!--        v-else-if="show == 'validation_page'"-->
<!--        key="account_validation"-->
<!--        :mail="email"-->
<!--        mode="account_validation"-->
<!--      />-->
<!--      <onboarding-->
<!--        v-else-if="show == 'password_recovery'"-->
<!--        key="password_recovery"-->
<!--        :mail="email"-->
<!--        mode="password_recovery"-->
<!--        @validated="subtitle = '';"-->
<!--      />-->
    </transition>
  </div>
</template>

<script>
import FloatLabel from 'vue-float-label/components/FloatLabel.vue';
import SimpleVueValidator from 'simple-vue-validator';
import Spinner from './utility/Spinner.vue';
// import Onboarding from '@/components/DentalUser/Onboarding';

SimpleVueValidator.setMode('conservative');

const Validator = SimpleVueValidator.Validator;

export default {
  name: 'login-component',
  mixins: [SimpleVueValidator.mixin],
  components: {
    FloatLabel,
    Spinner,
    // Onboarding,
  },
  directives: {
    focus: {
      inserted: function inserted(el) {
        if (el.tagName === 'INPUT') {
          el.focus();
        } else {
          el.querySelector('input').focus();
        }
      },
    },
  },
  data() {
    return {
      loading: false,
      statusMessage: 'Loading',
      failureMessage: '',
      invalidAccount: false,
      password: '',
      invalidPassword: false,
      email: '',
      show: 'email',
      title: 'Login',
      subtitle: 'Enter your email address to access your account',
      validationCode: ['', '', '', '', '', ''],
    };
  },
  computed: {
    validationCodeString: function validationCodeString() {
      return this.validationCode.join('').length === 6
        ? `${this.validationCode[0]}${this.validationCode[1]}${
          this.validationCode[2]
        }-${this.validationCode[3]}${this.validationCode[4]}${
          this.validationCode[5]
        }`
        : '';
    },
  },
  validators: {
    email: function email(e) {
      return Validator.value(e)
        .required()
        .email()
        .custom(() => {
          if (this.invalidAccount) {
            this.invalidAccount = false;
            return 'No account could be found for this email address.';
          }
          return undefined;
        });
    },
    password: function password(value) {
      return Validator.value(value)
        .required()
        .custom(() => {
          if (this.invalidPassword) {
            this.invalidPassword = false;
            return 'Incorrect password';
          }
          return undefined;
        });
    },
  },
  methods: {
    submitEmail: function submitEmail() {
      this.$validate('email').then((success) => {
        if (success) {
          this.show = 'spinner';
          this.login(false);
        }
      });
    },
    submitPassword: function submitPassword() {
      this.$validate('password').then((success) => {
        if (success) {
          this.show = 'spinner';
          this.login(true);
        }
      });
    },
    enterPasswordRecovery() {
      this.show = 'password_recovery';
      this.title = 'Reset My Password';
      this.subtitle = 'Validate your account ownership to continue';
    },
    login: function login(password) {
      const options = { user: this.email };
      if (!password) {
        this.show = 'password';
        this.subtitle = 'Enter your password to continue';
        return;
      }
      options.password = this.password;
      this.$post('/login', options)
        .then((response) => {
          console.log("login Response: ", response.data)
          if (response.data.csrf) {
            // Setting CSRF Token to Storage
            localStorage.setItem('X-CSRF-Token', response.data.csrf)
            this.$setCSRFToken(response.data.csrf);
          }
          if (response.data.api_key) {
            this.$setAPIKey(response.data.api_key);
          }
          switch (response.data.status) {
            case 'success':
              // @TODO: Write to vuex store.
              window.me = {};
              window.me.uuid = response.data.uuid;
              window.me.api_key = response.data.api_key;
              window.me.first_name = response.data.details.first_name;
              window.me.last_name = response.data.details.last_name;
              window.me.email = response.data.details.email;
              window.me.organization_id = response.data.details.organization_id;
              localStorage.setItem("me", JSON.stringify(window.me));
              this.$router.push('/dashboard');
              break;
            case 'unvalidated_account':
              this.title = 'Validate Account';
              this.subtitle = '';
              this.show = 'validation_page';
              break;
            default:
              break;
          }
        })
        .catch((error) => {
          if (error.response) {
            switch (error.response.data.status) {
              case 'incorrect_password':
                this.invalidPassword = true;
                this.$validate('password');
                this.show = 'password';
                break;
              default:
                break;
            }
          } else {
            this.show = 'email';
          }
        });
    },
  },
};
</script>

<style>
.vfl-label {
  text-align: left;
}

.vfl-has-label {
  margin-top: 20px;
}

.vfl-has-label.error input {
  border-bottom: 2px solid #ff8888;
}
</style>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.start-link {
  display: inline-block;
  margin-top: 20px;
}

.control.action {
  margin-top: 20px;
}

.hidden-input {
  display: none;
}
</style>
