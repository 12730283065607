import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
import Onboarding from '../components/Onboarding.vue';
import Login from '../components/Login.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    children: [
      {
        name: 'onboarding',
        path: '',
        component: Onboarding,
      },
      {
        name: 'login',
        path: 'login',
        component: Login,
      },
    ],
    beforeEnter: (to, from, next) => {
      console.log("homepage auth middleware triggered!")
      const userObject = localStorage.getItem('me');
      if (userObject) {
        console.log("Routing to dashboard...")
        return next({
          path: '/dashboard'
        })
      }
      next();
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path: '/dashboard',
    name: 'Dashboard page',
    component: () =>
      import(/* webpackChunkName: "user" */ '@/components/Dashboard/Main'),
    children: [
      {
        path: '',
        component: () =>
          import(/* webpackChunkName: "user" */ '@/components/Dashboard/Dashboard')
      },
      {
        path: 'welcome',
        component: () =>
          import(/* webpackChunkName: "user" */ '@/components/Dashboard/Welcome')
      },
      {
        name: 'Account Settings',
        path: 'account',
        component: () =>
          import(/* webpackChunkName: "user" */ '@/components/Dashboard/AccountInfo')
      },
      {
        name: 'My Programs',
        path: 'programs',
        component: () =>
            import(/* webpackChunkName: "programs" */ '@/components/Dashboard/Programs')
      },
      {
        path: '/dashboard/*',
        name: 'Not found',
        redirect: '/dashboard'
      }
    ],
    beforeEnter: (to, from, next) => {
      console.log("dashboard auth middleware triggered!")
      const userObject = localStorage.getItem('me');
      if (!userObject) {
        console.log("Not Loggedin! Routing to homepage...")
        return next({
          path: '/'
        })
      }
      next();
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
